<template>
  <div class="pre-body pre-body-md">
    <div class="pre-subhead pre-subhead-border centered">
      <div class="pre-divider"></div>
      <div class="pre-subhead--copy">About</div>
      <div class="pre-divider"></div>
    </div>

    <div class="pre-pad"></div>

    <div class="pre-cols">
      <div class="pre-col-1-3">
        <lazy-img src="/assets/images/logoGlitch.gif" alt="" />
      </div>

      <div class="pre-col-2-3">
        <div class="pre-copy">
          <p>
            The Paranormal Institute is a prestigious establishment founded
            after the incidence of psychic occurrences increased ten-fold during
            the summer of 1984. The Ghostbusters shot to global fame following
            their heroic take-down of Gozer (a maniacal deity set on bringing
            about the destruction of the world) and the Stay Puft Marshmallow
            Man.
          </p>
          <p>
            Having established the indispensable defence science of the next
            decade, Professional Paranormal Investigations and Eliminations,
            they founded the Paranormal Institute to recruit brave and curious
            minds to join the Ghostbuster ranks. Our panel of specialists will
            give you a complete education in ghosts, busting and paranormal
            phenomena.
          </p>
          <p>
            Don't wait for someone to save you. Do it yourself with the
            Paranormal Institute! Our courteous and efficient staff are on call
            24 hours a day to serve all your enrollment needs.
          </p>
        </div>
      </div>
    </div>

    <div class="pre-pad"></div>

    <div class="pre-subhead pre-subhead-border centered">
      <div class="pre-divider"></div>
      <div class="pre-subhead--copy">Meet the Team</div>
      <div class="pre-divider"></div>
    </div>

    <div class="pre-pad"></div>
    <lazy-img
      class="pre-image mb-5"
      src="/assets/images/meet-team.png"
      alt=""
    />
    <div class="mb-5">
      <div class="pre-copy">
        <p>Head Lecturer</p>
      </div>
      <div class="pre-subhead glowing">Dr Rudolph Whitehead</div>
      <div class="pre-copy pre-copy--small">
        <p>
          Dr Rudolph Whitehead is the author behind the bestselling book 'The
          Gates of Gozer' and self-appointed head-lecturer of the Paranormal
          Institute. An expert in ancient rituals, divining spirits, paranormal
          phenomena, and the politics of Gozerian religion, Dr. Whitehead is an
          invaluable addition to the team. Students will have the opportunity to
          ask Dr. Whitehead about his book and gain insight into the deity
          'Gozer the Destroyer'. Whitehead has been studying newly discovered
          documents relating to Ivo Shandor's work and will reveal his findings
          to students for the first time during his not-to-be-missed lecture.
          This final address to our new students will take place in Ivo
          Shandor’s apartment; beamed directly via Spirit Net across the globe
          to all our paying undergraduates.
        </p>
      </div>
    </div>

    <div class="pre-cols">
      <div class="pre-col-1-2">
        <div class="pre-copy">
          <p>Parapsychologist</p>
        </div>
        <div class="pre-subhead glowing">Dr. Blair Wyndham, Jr.</div>
        <div class="pre-copy pre-copy--small">
          <p>
            Having trained overseas at Edinburgh University's Parapsychology
            Research Unit, Wyndham Jnr. has a comprehensive knowledge of all
            things that occupy the twilight realm. Whilst Wyndham Jnr. did not
            technically complete their studies, their natural ability to
            communicate across the veil has earned them a sterling reputation
            amongst their peers. They will be teaching students how to spot,
            identify, communicate with ghosts, ghouls and spectres. During their
            studies, they specialised in full body possessions and their varied
            outcomes.
          </p>
        </div>
      </div>
      <div class="pre-col-1-2">
        <div class="pre-copy">
          <p>Technology Teacher</p>
        </div>
        <div class="pre-subhead glowing">Dr. Frankie Sherman</div>
        <div class="pre-copy pre-copy--small">
          <p>
            Frankie Sherman is the Paranormal Institute's technological lead.
            Sherman joined the Ghostbusters as tech support when Dr. Spengler's
            inventions became too advanced to maintain with just one pair of
            hands. They are proudly the 5th most qualified person in the world
            to teach the maintenance and field use of cutting edge technology
            like PKE meters, traps and proton packs. A self-taught mechanical
            engineer, Sherman has the skills and the know-how to get you up to
            speed on the technical aspects of Ghostbusting.
          </p>
        </div>
      </div>
    </div>

    <div class="pre-cols">
      <div class="pre-col-1-2">
        <div class="pre-copy">
          <p>Ivo Shandor Specialist</p>
        </div>
        <div class="pre-subhead glowing">Professor Kit Palladino</div>
        <div class="pre-copy pre-copy--small">
          <p>
            Professor Palladino originally studied English Literature at Harvard
            University but has since developed a fascination for, and academic
            interest in, the archaeology of fallen civilisations. Our
            professor’s work as a fellow at the American Museum of Natural
            History gave unprecedented access to long hidden Sumerian artefacts,
            which in turn fed into the work of Gozarian expert, Dr Rudolph
            Whitehead. Professor Palladino has since studied under Dr
            Whitehead's tutelage. Palladino is currently leading the research
            team exploring Ivo Shandor's recently discovered private study; a
            space accessed only recently following the discovery of new
            structural blueprints of 550 Central Park West, designed and built
            by Ivo Shandor himself. The Ghostbusters are thrilled that Professor
            Palladino was so eager to volunteer as one of our key tutors and
            welcome this dazzling academic mind with open arms. We are so
            excited to share Kit’s unique Shandorian insight with curious young
            minds.
          </p>
        </div>
      </div>
      <div class="pre-col-1-2">
        <div class="pre-copy">
          <p>Field Guide</p>
        </div>
        <div class="pre-subhead glowing">Janine Melnitz</div>
        <div class="pre-copy pre-copy--small">
          <p>
            Janine Melnitz is your guide to the Paranormal Institute. Since her
            humble beginnings as the Ghostbusters’ receptionist, Melnitz has
            soared through the ranks and is now a veritable expert in
            Ghostbusting. Her study has been primarily theoretical during the
            paranormal drought of the past year, but her hard work has earned
            her an honorary title of 'professor'. With exemplary people skills,
            Janine is sure to do the Ghostbusters proud.
          </p>
          <p>
            "I look forward to this compelling adventure into the spirit realm
            and beyond. Truly. This is a fabulous way to spend your spare time."
          </p>
          <p>- Janine Melnitz</p>
        </div>
      </div>
    </div>

    <div class="pre-pad"></div>

    <div class="pre-subhead pre-subhead-border centered">
      <div class="pre-divider"></div>
      <div class="pre-subhead--copy">Camera Operators &amp; Broadcast Team</div>
      <div class="pre-divider"></div>
    </div>

    <div class="pre-pad"></div>

    <div class="pre-cols align-top">
      <div class="pre-col-1-4">
        <div class="pre-copy">
          <p>Head Camera Operator</p>
        </div>
        <div class="pre-subhead glowing">Billy</div>
        <div class="pre-copy pre-copy--small">
          <p>
            Born and bred in NYC, Billy joined the Paranormal Institute after a
            surprise visit from his Grandmother a decade after she had died.
            Since their formation, he has been an avid follower of The
            Ghostbusters and is very excited to join the team.
          </p>
        </div>
        <div class="pre-copy">
          <p>
            "I can't believe my luck - becoming one of the Ghostbusters! I hope
            I do a good job. Do you think the guys will like me? What exactly is
            it I'll be doing?"
          </p>
        </div>
      </div>
      <div class="pre-col-1-4">
        <div class="pre-copy">
          <p>Camera Operator</p>
        </div>
        <div class="pre-subhead glowing">Miriam</div>
        <div class="pre-copy pre-copy--small">
          <p>
            Born in New Jersey, Miriam is no stranger to unusual people and
            persuasions. Miriam has had several encounters with apparitions but
            has not yet managed to capture any on camera. She hopes that her
            time with the Paranormal Institute will change that.
          </p>
        </div>
        <div class="pre-copy">
          <p>
            "This is the perfect job for me to finally capture the spirits that
            have revealed themselves to me. Maybe I'll even get on TV!"
          </p>
        </div>
      </div>
      <div class="pre-col-1-4">
        <div class="pre-copy">
          <p>Camera Operator</p>
        </div>
        <div class="pre-subhead glowing">Leah</div>
        <div class="pre-copy pre-copy--small">
          <p>
            Leah was at the base of the Shandor Building during the coming of
            Gozer and witnessed the Ghostbusters' work first-hand. Since then,
            she has been a dedicated member of the Paranormal Institute.
          </p>
        </div>
        <div class="pre-copy">
          <p>
            "My position in the Institute is a result of hard work and knowing
            the right people. Did I mention I was next to Dr. Stanz's Uncle's
            Sister's Husband when it was raining marshmallows last year?"
          </p>
        </div>
      </div>
      <div class="pre-col-1-4">
        <div class="pre-copy">
          <p>Camera Operator</p>
        </div>
        <div class="pre-subhead glowing">Ted</div>
        <div class="pre-copy pre-copy--small">
          <p>
            Ted has had many interactions with paranormal phenomena. Nothing
            malevolent, though. Aside from the nightmares he has had for the
            past 15 years.
          </p>
        </div>
        <div class="pre-copy">
          <p>"I just want a good night's sleep."</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "About",
  data() {
    return {
      profiles: [],
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.container {
  max-width: 800px;
}
</style>
